@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Lexend+Deca:wght@300&display=swap');

:root {
  --color-job-background: #ffffff;
  --color-job-hover: #CED0CE;
  --color-job-border: #00767e;
}

/* Gradient */

.animated-gradient-bg {
  background: linear-gradient(90deg, #00767e,  #3D2645, #f50057);
  background-size: 600% 600%;
  -webkit-animation: gradient 10s ease-in-out infinite;
          animation: gradient 10s ease-in-out infinite;
}


@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%
  }
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%
  }
}

/* intro video */

.intro-video {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(8px); /* Adjust the blur radius as needed */
}

.intro-video-text {
  position: relative;
  z-index: 1; /* Ensure content is on top of the video */
}

.intro-side-video {
  -o-object-fit: cover;
     object-fit: cover;
  width: 50%;
}
/*  */

.update-form-container {
  background-color: rgb(237, 237, 237);
  padding: 1rem;
}

.form-container {
  background-color: rgb(237, 237, 237);
  padding: 3rem;
}

.form-container-fitting {
  background-color: rgb(237, 237, 237);
  padding: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.filepond-container {
  border: dotted 2px darkgray;
  width: 100%;
}

.job-container {
  background-color: var(--color-job-background);
  border: var(--color-job-border) solid 1px;
  padding: 0.5rem 2rem;
  position: 'relative';
}

.job-container:hover {
  background-color: var(--color-job-hover);
}

.button-spinner {
  color: white;
  margin-left: 0.75rem;
}

.underline-bold-text {
  font-weight: bold;
  text-decoration: underline;
}

